.FormContainer {
    max-width: 900px;
    margin: auto;
}

.FormContainer h2 {
    margin: 30px 0 20px 0;
}

.FlexHeader {
    margin: 30px 0 20px 0;
}

.FlexHeader h2 {
    margin: 0;
}

.FlexHeader p {
    margin: 5px 0 0px 0;
    font-size: 15px;
}

.FormContainerBox {
    width: calc(100% - 20px);
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px 20px;
}

.teamsCont {
    width: calc(100% - 20px);
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    /* margin-bottom: 30px; */
}

.labelPhone {
    position: absolute;
    top: 3px;
    z-index: 1;
    left: 70px;
    font-size: 12px;
    background: white;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
}

.error {
    font-size: 12px;
    color: red;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: -5px;
}

.FlexHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 900px) {
    .FormContainerBox {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
    }

    .teamsCont {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        gap: 20px;
    }

    .error {
        font-size: 10px;
    }

    .FlexHeader {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .FlexHeader h3 {
        margin-bottom: 0;
    }

    .FlexHeader p {
        margin-bottom: 10px;
    }
}