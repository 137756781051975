.CounterNumber {
    width: 140px;
    height: 120px;
    border-radius: 10px;
    font-size: 50px;
    color: #830d0b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
}

@media screen and (max-width: 600px) {
    .CounterNumber {
        width: 120px;
        height: 100px;
        border-radius: 10px;
        font-size: 35px;

    }
}