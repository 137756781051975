.SocialNavIcon {
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
}

.NavLinks {
    font-size: 17px !important;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    font-weight: 600 !important;
}

.NavLinks:hover {
    cursor: pointer;
    color: #6e1010;

}