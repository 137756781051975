.SocialNavIcon {
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
}

.NavLinks, .NavLinksReg {
    font-size: 17px !important;
    cursor: pointer;
    padding: 15px 20px;
    display: flex;
    font-weight: 600 !important;
}

.InsideLink {
    margin-left: 30px !important;
}

.NavLinks:hover {
    cursor: pointer;
    color: #6e1010;
}


.drawer .MuiPaper-root {
    width: 100%;
    max-width: 70vw;
    min-width: 200px;
}

.registration {
    position: relative;
}

.registration:hover .navMenu, .navMenu:hover {
    display: block;
}

.navMenu {
    display: none;
    position: absolute;
    width: max-content;
    background-color: white;
    top: 26px;
    left: -20px;
    list-style-type: none;
    padding: 20px 0 0 0;
    /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1); */
    /* z-index: 10; */
}

.navMenu li {
    width: 200px;
    padding: 15px;
    color: black;
    cursor: pointer;
}

.navMenu li:hover {
    color: #6e1010;
    background-color: rgba(0, 0, 0, 0.1);
}